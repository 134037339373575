import { Colors } from './types'

export const baseColors = {
  failure: '#2EA39B',
  primary: '#2EA39B',
  primaryBright: '#FFE583',
  primaryDark: '#2EA39B',
  secondary: '#F0C803',
  success: '#2EA39B',
  warning: '#FFB237',
}

export const brandColors = {
  binance: '#F0B90B',
}

export const lightColors: Colors = {
  ...baseColors,
  ...brandColors,
  secondary: '#2EA39B',
  background: '#2B2B2B',
  backgroundDisabled: '#C7C7C7',
  contrast: '#FFFFFF',
  invertedContrast: 'rgba(26, 26, 39, 0.9) none repeat scroll 0% 0%',
  input: '#444444',
  inputSecondary: '#444444',
  primaryDark: '#2EA39B',
  tertiary: '#4440',
  text: '#FFFFFF',
  textDisabled: '#2EA39B',
  textSubtle: '#FFFFFF',
  borderColor: '#C7C7C7',
  card: 'rgba(26, 26, 39, 0.6) none repeat scroll 0% 0%',
  extra: '#2EA39B',
  button: 'linear-gradient(90deg, #2EA39B 0%, #7C6A25 100%)',
  farming: 'rgba(255, 255, 255, 0.1)',
  gradients: {
    bubblegum: 'linear-gradient(139.73deg, #313D5C 0%, #3D2A54 100%)',
  },
}

export const darkColors: Colors = lightColors
