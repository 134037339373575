import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from 'uikit/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Bahnschrift";
    src: URL("../public/fonts/bahnschrift.ttf");
  }

  * {
    font-family: 'Kanit', sans-serif;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }

  .kGDvMA {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

#video-fond {
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
#video-fond > video {
  position: absolute;
  top: 0;
  left: 0;

  min-width: 100%; 
  min-height: 100%; 

  width: auto;
  height: auto;
}
`

export default GlobalStyle
