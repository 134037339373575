const tokens = {
  wnear: {
    symbol: 'WNEAR',
    address: {
      4: '0x4cF0be81C7a959A05026aE7246ad7fBcA6048082',
    },
    decimals: 18,
    projectLink: 'https://auroraswap.finance/',
  },
  weth: {
    symbol: 'WETH',
    address: {
      4: '0x85e4b0E1be5022F9E4b576C7118F8A90B8b22C12',
    },
    decimals: 18,
    projectLink: 'https://auroraswap.finance/',
  },
  beam: {
    symbol: 'BEAM',
    address: {
      4: '0xa7CDD4283DAbe7F67E0aCc64b38582A84bA930F8',
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },
  aurora: {
    symbol: 'AURORA',
    address: {
      4: '0xf02063301a47a2080d373AF3F8C7938d8D84eD42',
    },
    decimals: 18,
    projectLink: 'https://auroraswap.finance/',
  },
  usdc: {
    symbol: 'USDC',
    address: {
      4: '0x8aC04Bb1b46FbD569b2d78B42650A7EF508417b8',
    },
    decimals: 18,
    projectLink: 'https://auroraswap.finance/',
  },
  usdt: {
    symbol: 'USDT',
    address: {
      4: '0x46458435Da422618798B3f74cd6f71Dbe4a1a33d',
    },
    decimals: 18,
    projectLink: 'https://auroraswap.finance/',
  },
}

export default tokens
