export default {
  masterChef: {
    4: '0xf791D9D767ac028976424018f3D924cb8ba493C7',
  },
  sousChef: {
    4: '',
  },
  lottery: {
    4: '',
  },
  lotteryNFT: {
    4: '',
  },
  mulltiCall: {
    4: '0x8A926dF143FE4472B16d54144d6ca77D764D579d',
  },
  pancakeProfile: {
    4: '',
  },
  pancakeRabbits: {
    4: '',
  },
  bunnyFactory: {
    4: '',
  },
  claimRefund: {
    4: '',
  },
  pointCenterIfo: {
    4: '',
  },
  bunnySpecial: {
    4: '',
  },
}
