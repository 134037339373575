import styled, { DefaultTheme } from 'styled-components'
import { Variant, variants } from '../Button/types'

type StyledButtonMenuProps = {
  variant: Variant
  theme: DefaultTheme
}

const getBackgroundColor = ({ theme, variant }: StyledButtonMenuProps) => {
  return theme.colors[variant === variants.SUBTLE ? 'input' : 'tertiary']
}

const StyledButtonMenu = styled.div<{ variant: Variant }>`
  background-color: ${getBackgroundColor};
  box-shadow: rgba(244, 155, 51, 0.15) 0px 0px 24px;
  border: 1px solid rgba(244, 155, 51, 0.25);
  border-radius: 16px;
  display: inline-flex;

  & > button + button,
  & > a + a {
    margin-left: 2px; // To avoid focus shadow overlap
  }
`

export default StyledButtonMenu
