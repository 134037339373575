import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'BEAM-WNEAR LP',
    lpAddresses: {
      4: '0xE78D3713BA9bDA422931367410aCF7ed375551EE',
    },
    token: tokens.beam,
    quoteToken: tokens.wnear,
  },
  {
    pid: 1,
    lpSymbol: 'BEAM-WETH LP',
    lpAddresses: {
      4: '0x105504eA73A418216B6afCbDc657925EEcd26CA8',
    },
    token: tokens.beam,
    quoteToken: tokens.weth,
  },
  {
    pid: 2,
    lpSymbol: 'AURORA-BEAM LP',
    lpAddresses: {
      4: '0xFC9b5E279Ed8A69F1E4168dFBa98f1e2ad27A956',
    },
    token: tokens.aurora,
    quoteToken: tokens.beam,
  },
  {
    pid: 3,
    lpSymbol: 'USDC-USDT LP',
    lpAddresses: {
      4: '0xcf13B1e29371B81339FcF5195f87657E6f3AC2C6',
    },
    token: tokens.usdc,
    quoteToken: tokens.usdt,
  },
  {
    pid: 4,
    lpSymbol: 'WETH-WNEAR LP',
    lpAddresses: {
      4: '0x03296903cA92c5DbD89a344233b883c49F8A9347',
    },
    token: tokens.weth,
    quoteToken: tokens.wnear,
  },
  {
    pid: 5,
    lpSymbol: 'USDC-WNEAR LP',
    lpAddresses: {
      4: '0x7844FD6790d2eCB57a6041A69eEBeE6078798f6C',
    },
    token: tokens.usdc,
    quoteToken: tokens.wnear,
  },
  {
    pid: 6,
    lpSymbol: 'USDT-WNEAR LP',
    lpAddresses: {
      4: '0xcec1C5a9602780Bd9f273e8F5FF89ef6B6f91dA0',
    },
    token: tokens.usdt,
    quoteToken: tokens.wnear,
  },
]

export default farms
