export const links = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://swap.auroraswap.finance',
      },
      {
        label: 'Liquidity',
        href: 'https://swap.auroraswap.finance/#/pool',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: '/syrup',
  },
  {
    label: 'Info',
    icon: 'InfoIcon',
    items: [
      {
        label: 'Overview',
        href: 'https://info.auroraswap.finance',
      },
      {
        label: 'Tokens',
        href: 'https://info.auroraswap.finance/tokens',
      },
      {
        label: 'Pairs',
        href: 'https://info.auroraswap.finance/pairs',
      },
      {
        label: 'Accounts',
        href: 'https://info.auroraswap.finance/accounts',
      },
    ],
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Docs',
        href: 'https://docs.auroraswap.finance',
      },
      {
        label: 'Blog',
        href: 'https://auroraswap.medium.com',
      },
    ],
  },
]

export const socials = [
  {
    label: 'Telegram',
    icon: 'TelegramIcon',
    items: [
      {
        label: 'English',
        href: 'https://t.me/pancakeswap',
      },
      {
        label: 'Bahasa Indonesia',
        href: 'https://t.me/PancakeSwapIndonesia',
      },
      {
        label: '中文',
        href: 'https://t.me/PancakeSwap_CN',
      },
      {
        label: 'Tiếng Việt',
        href: 'https://t.me/PancakeSwapVN',
      },
      {
        label: 'Italiano',
        href: 'https://t.me/pancakeswap_ita',
      },
      {
        label: 'русский',
        href: 'https://t.me/pancakeswap_ru',
      },
      {
        label: 'Türkiye',
        href: 'https://t.me/pancakeswapturkiye',
      },
      {
        label: 'Português',
        href: 'https://t.me/PancakeSwapPortuguese',
      },
      {
        label: 'Español',
        href: 'https://t.me/PancakeswapEs',
      },
      {
        label: '日本語',
        href: 'https://t.me/pancakeswapjp',
      },
      {
        label: 'Français',
        href: 'https://t.me/pancakeswapfr',
      },
      {
        label: 'Announcements',
        href: 'https://t.me/PancakeSwapAnn',
      },
      {
        label: 'Whale Alert',
        href: 'https://t.me/PancakeSwapWhales',
      },
    ],
  },
  {
    label: 'Twitter',
    icon: 'TwitterIcon',
    href: 'https://twitter.com/pancakeswap',
  },
]

export const MENU_HEIGHT = 64
export const MENU_ENTRY_HEIGHT = 48
export const SIDEBAR_WIDTH_FULL = 240
export const SIDEBAR_WIDTH_REDUCED = 56
