import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = () => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" version="1.1" viewBox="0 0 24 24">
      <path
        fill="#f7c414"
        strokeWidth="0.32"
        d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z"
      />
      <path
        fill="#373737"
        strokeWidth="0.32"
        d="M7.744 9.675c-2.03-.02-4.06 0-6.109 0-.295 0-.374.118-.354.374.04.769.61 1.4 1.38 1.616h4.295v.256H3.369c0 .02.02.04.02.079.197.887.69 1.438 1.498 1.695h2.049v.256H5.478s0 .02.02.02c.256.926.985 1.477 1.97 1.517 1.734.078 3.153-1.163 3.212-2.838.098-1.537-1.261-2.955-2.936-2.975z"
      />
      <path
        fill="#373737"
        strokeWidth="0.32"
        d="M22.384 9.675c-2.03 0-4.059-.02-6.108 0-1.695.02-3.074 1.419-2.976 2.975.099 1.695 1.498 2.916 3.212 2.838 1.005-.04 1.715-.591 1.97-1.518 0 0 0-.02.02-.02h-1.458v-.255h2.07c.807-.257 1.28-.808 1.497-1.695 0-.02.02-.04.02-.079h-3.567v-.256h4.296c.788-.217 1.36-.887 1.379-1.616.02-.256-.079-.374-.355-.374z"
      />
    </Svg>
  )
}

export default Icon
